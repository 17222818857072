<template>
  <div class="navigation">
    <div class="navigation__menu">
      <el-menu @select="setRoute" v-if="isUserRoleAdministrator">
        <el-menu-item index="AdministrationAdministratorDashboardIndex">
          <template #title>
            <span class="icon">
              <House />
            </span>
            <span class="label">
              {{ translation.dashboard }}
            </span>
          </template>
        </el-menu-item>
        <span class="separator">
          <span class="separator__title">{{ translation.content }}</span>
        </span>
        <el-menu-item index="AdministrationAdministratorChannelsIndex">
          <template #title>
            <span class="icon">
              <Monitor />
            </span>
            <span class="label">
              {{ translation.channels }}
            </span>
          </template>
        </el-menu-item>
        <el-menu-item index="AdministrationAdministratorVideosIndex">
          <template #title>
            <span class="icon">
              <Film />
            </span>
            <span class="label">
              {{ translation.videos }}
            </span>
          </template>
        </el-menu-item>
        <el-menu-item index="AdministrationAdministratorCategoriesIndex">
          <template #title>
            <span class="icon">
              <Collection />
            </span>
            <span class="label">
              {{ translation.categories }}
            </span>
          </template>
        </el-menu-item>
        <el-menu-item index="AdministrationAdministratorTagsIndex">
          <template #title>
            <span class="icon">
              <List />
            </span>
            <span class="label">
              {{ translation.tags }}
            </span>
          </template>
        </el-menu-item>
        <span class="separator">
          <span class="separator__title">{{ translation.pages }}</span>
        </span>
        <el-menu-item index="AdministrationAdministratorPagesViewIndex">
          <template #title>
            <span class="icon">
              <House />
            </span>
            <span class="label">
              {{ translation.homePage }}
            </span>
          </template>
        </el-menu-item>
        <el-menu-item index="AdministrationAdministratorPagesIndex">
          <template #title>
            <span class="icon">
              <Files />
            </span>
            <span class="label">
              {{ translation.pages }}
            </span>
          </template>
        </el-menu-item>
        <span class="separator">
          <span class="separator__title">{{ translation.settings }}</span>
        </span>
        <el-sub-menu index="AdministrationAdministratorSettingsIndex">
          <template #title>
            <span class="icon">
              <Setting />
            </span>
            <span class="label">{{ translation.settings }}</span>
          </template>
          <el-menu-item index="AdministrationAdministratorSettingsAlgorithmsIndex">
            <template #title>
              <span class="label">
                {{ translation.algorithms }}
              </span>
            </template>
          </el-menu-item>
          <el-menu-item index="AdministrationAdministratorSettingsFunctionalitiesIndex">
            <template #title>
              <span class="label">
                {{ translation.functionalities }}
              </span>
            </template>
          </el-menu-item>
          <el-menu-item index="AdministrationAdministratorSettingsGroupsIndex">
            <template #title>
              <span class="label">
                {{ translation.groups }}
              </span>
            </template>
          </el-menu-item>
        </el-sub-menu>
        <el-menu-item index="AdministrationAdministratorUsersIndex">
          <template #title>
            <span class="icon">
              <User />
            </span>
            <span class="label">
              {{ translation.users }}
            </span>
          </template>
        </el-menu-item>
      </el-menu>
      <el-menu @select="setRoute" v-if="isUserRoleClient">
        <el-menu-item index="AdministrationClientDashboardIndex">
          <template #title>
            <span class="icon">
              <House />
            </span>
            <span class="label">
              {{ translation.dashboard }}
            </span>
          </template>
        </el-menu-item>
        <span class="separator">
          <span class="separator__title">{{ translation.content }}</span>
        </span>
        <el-menu-item index="AdministrationClientChannelsIndex">
          <template #title>
            <span class="icon">
              <Monitor />
            </span>
            <span class="label">
              {{ translation.channels }}
            </span>
          </template>
        </el-menu-item>
      </el-menu>
      <el-menu @select="setRoute" v-if="isUserRoleSuperAdministrator">
        <el-menu-item index="AdministrationSuperAdministratorDashboardIndex">
          <template #title>
            <span class="icon">
              <House />
            </span>
            <span class="label">
              {{ translation.dashboard }}
            </span>
          </template>
        </el-menu-item>
        <span class="separator">
          <span class="separator__title">{{ translation.content }}</span>
        </span>
        <el-menu-item index="AdministrationSuperAdministratorChannelsIndex">
          <template #title>
            <span class="icon">
              <Monitor />
            </span>
            <span class="label">
              {{ translation.channels }}
            </span>
          </template>
        </el-menu-item>
        <el-menu-item index="AdministrationSuperAdministratorVideosIndex">
          <template #title>
            <span class="icon">
              <Film />
            </span>
            <span class="label">
              {{ translation.videos }}
            </span>
          </template>
        </el-menu-item>
        <el-menu-item index="AdministrationSuperAdministratorCategoriesIndex">
          <template #title>
            <span class="icon">
              <Collection />
            </span>
            <span class="label">
              {{ translation.categories }}
            </span>
          </template>
        </el-menu-item>
        <el-menu-item index="AdministrationSuperAdministratorTagsIndex">
          <template #title>
            <span class="icon">
              <List />
            </span>
            <span class="label">
              {{ translation.tags }}
            </span>
          </template>
        </el-menu-item>
        <span class="separator">
          <span class="separator__title">{{ translation.pages }}</span>
        </span>
        <el-menu-item index="AdministrationSuperAdministratorPagesViewIndex">
          <template #title>
            <span class="icon">
              <House />
            </span>
            <span class="label">
              {{ translation.homePage }}
            </span>
          </template>
        </el-menu-item>
        <el-menu-item index="AdministrationSuperAdministratorPagesIndex">
          <template #title>
            <span class="icon">
              <Files />
            </span>
            <span class="label">
              {{ translation.pages }}
            </span>
          </template>
        </el-menu-item>
        <span class="separator">
          <span class="separator__title">{{ translation.settings }}</span>
        </span>
        <el-sub-menu index="AdministrationSuperAdministratorSettingsIndex">
          <template #title>
            <span class="icon">
              <Setting />
            </span>
            <span class="label">{{ translation.settings }}</span>
          </template>
          <el-menu-item index="AdministrationSuperAdministratorSettingsAlgorithmsIndex">
            <template #title>
              <span class="label">
                {{ translation.algorithms }}
              </span>
            </template>
          </el-menu-item>
          <el-menu-item index="AdministrationSuperAdministratorSettingsFunctionalitiesIndex">
            <template #title>
              <span class="label">
                {{ translation.functionalities }}
              </span>
            </template>
          </el-menu-item>
          <el-menu-item index="AdministrationSuperAdministratorSettingsGroupsIndex">
            <template #title>
              <span class="label">
                {{ translation.groups }}
              </span>
            </template>
          </el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="AdministrationSuperAdministratorLogsIndex">
          <template #title>
            <span class="icon">
              <Warning />
            </span>
            <span class="label">{{ translation.logs }}</span>
          </template>
          <el-menu-item index="AdministrationSuperAdministratorLogsExceptionLogsIndex">
            <template #title>
              <span class="label">
                {{ translation.exceptionLogs }}
              </span>
            </template>
          </el-menu-item>
          <el-menu-item index="AdministrationSuperAdministratorLogsActivityLogsIndex">
            <template #title>
              <span class="label">
                {{ translation.activityLogs }}
              </span>
            </template>
          </el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="AdministrationSuperAdministratorNotificationsIndex">
          <template #title>
            <span class="icon">
              <Warning />
            </span>
            <span class="label">{{ translation.notifications }}</span>
          </template>
          <el-menu-item index="AdministrationSuperAdministratorNotificationsDevicesIndex">
            <template #title>
              <span class="label">
                {{ translation.devices }}
              </span>
            </template>
          </el-menu-item>
          <el-menu-item index="AdministrationSuperAdministratorNotificationsSubscriptionsIndex">
            <template #title>
              <span class="label">
                {{ translation.subscriptions }}
              </span>
            </template>
          </el-menu-item>
          <el-menu-item index="AdministrationSuperAdministratorNotificationsEventsIndex">
            <template #title>
              <span class="label">
                {{ translation.events }}
              </span>
            </template>
          </el-menu-item>
          <el-menu-item index="AdministrationSuperAdministratorNotificationsScheduledEventsIndex">
            <template #title>
              <span class="label">
                {{ translation.scheduledEvents }}
              </span>
            </template>
          </el-menu-item>
        </el-sub-menu>
        <el-menu-item index="AdministrationSuperAdministratorUsersIndex">
          <template #title>
            <span class="icon">
              <User />
            </span>
            <span class="label">
              {{ translation.users }}
            </span>
          </template>
        </el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script lang="ts">
// Vue
import { Options, Vue } from "vue-class-component";

// Store
import store from "@/store";

// Services
import { getTranslation } from "@/services/app/translation";

// Components
import { ElMenu, ElMenuItemGroup, ElMenuItem, ElSubMenu } from "element-plus";
import { Collection, Files, Film, House, List, Monitor, Notification, User, Tools, Operation, Pointer, Setting, Warning } from "@element-plus/icons-vue";

@Options({
  components: {
    ElMenu,
    ElMenuItemGroup,
    ElMenuItem,
    ElSubMenu,
    Collection,
    Files,
    Film,
    House,
    List,
    Monitor,
    Notification,
    User,
    Tools,
    Operation,
    Pointer,
    Setting,
    Warning,
  },
})
export default class Navigation extends Vue {
  get translation(): any {
    return getTranslation([
      "activityLogs",
      "algorithms",
      "dashboard",
      "devices",
      "categories",
      "channels",
      "content",
      "events",
      "exceptionLogs",
      "functionalities",
      "groups",
      "homePage",
      "logs",
      "notifications",
      "tags",
      "pages",
      "scheduledEvents",
      "settings",
      "subscriptions",
      "users",
      "videos",
    ]);
  }

  get isUserRoleAdministrator(): boolean {
    return store.getters.getUser.role === "administrator";
  }

  get isUserRoleClient(): boolean {
    return store.getters.getUser.role === "client";
  }

  get isUserRoleSuperAdministrator(): boolean {
    return store.getters.getUser.role === "super-administrator";
  }

  setRoute(routeName: string): void {
    switch (routeName) {
      case "AdministrationAdministratorPagesViewIndex":
        this.$router.push({
          name: routeName,
          params: {
            page_id: 1,
          },
        });
        break;
      case "AdministrationSuperAdministratorPagesViewIndex":
        this.$router.push({
          name: routeName,
          params: {
            page_id: 1,
          },
        });
        break;
      default:
        this.$router.push({
          name: routeName,
        });
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/core/_variables.scss";
@import "~@/assets/scss/mixins/_breakpoints.scss";

.navigation {
  width: 200px;
  min-width: 200px;
  min-height: calc(100vh - 134px);
  padding: 16px;
  border-right: 1px solid $grey-60;

  &__menu {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    :deep(.el-menu) {
      background-color: transparent;
      border-right: 0;
    }

    .el-sub-menu {
      border-radius: 4px;

      .icon {
        width: 16px;
        margin-right: 8px;
        position: relative;
        top: -2px;
      }

      .label {
        font-size: 14px;
        font-weight: 400;
        font-family: $secondary-font;
      }

      * {
        vertical-align: middle;
      }

      .el-menu {
        .el-menu-item {
          margin-left: 24px;
        }
      }
    }

    .el-menu-item {
      height: 48px;
      padding-right: 12px !important;
      padding-left: 12px !important;
      border-radius: 4px;

      .icon {
        width: 16px;
        margin-right: 8px;
        position: relative;
        top: -2px;
      }

      .label {
        font-size: 14px;
        font-weight: 400;
        font-family: $secondary-font;
      }

      * {
        vertical-align: middle;
      }

      &.is-active {
        color: $primary;
      }
    }

    .separator {
      display: block;
      margin-top: 24px;
      margin-bottom: 12px;
      padding: 0 4px;

      &__title {
        font-size: 12px;
        font-weight: 700;
        font-family: $secondary-font;
      }
    }
  }
}
</style>
