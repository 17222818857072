import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13175e76"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "navigation" }
const _hoisted_2 = { class: "navigation__menu" }
const _hoisted_3 = { class: "icon" }
const _hoisted_4 = { class: "label" }
const _hoisted_5 = { class: "separator" }
const _hoisted_6 = { class: "separator__title" }
const _hoisted_7 = { class: "icon" }
const _hoisted_8 = { class: "label" }
const _hoisted_9 = { class: "icon" }
const _hoisted_10 = { class: "label" }
const _hoisted_11 = { class: "icon" }
const _hoisted_12 = { class: "label" }
const _hoisted_13 = { class: "icon" }
const _hoisted_14 = { class: "label" }
const _hoisted_15 = { class: "separator" }
const _hoisted_16 = { class: "separator__title" }
const _hoisted_17 = { class: "icon" }
const _hoisted_18 = { class: "label" }
const _hoisted_19 = { class: "icon" }
const _hoisted_20 = { class: "label" }
const _hoisted_21 = { class: "separator" }
const _hoisted_22 = { class: "separator__title" }
const _hoisted_23 = { class: "icon" }
const _hoisted_24 = { class: "label" }
const _hoisted_25 = { class: "label" }
const _hoisted_26 = { class: "label" }
const _hoisted_27 = { class: "label" }
const _hoisted_28 = { class: "icon" }
const _hoisted_29 = { class: "label" }
const _hoisted_30 = { class: "icon" }
const _hoisted_31 = { class: "label" }
const _hoisted_32 = { class: "separator" }
const _hoisted_33 = { class: "separator__title" }
const _hoisted_34 = { class: "icon" }
const _hoisted_35 = { class: "label" }
const _hoisted_36 = { class: "icon" }
const _hoisted_37 = { class: "label" }
const _hoisted_38 = { class: "separator" }
const _hoisted_39 = { class: "separator__title" }
const _hoisted_40 = { class: "icon" }
const _hoisted_41 = { class: "label" }
const _hoisted_42 = { class: "icon" }
const _hoisted_43 = { class: "label" }
const _hoisted_44 = { class: "icon" }
const _hoisted_45 = { class: "label" }
const _hoisted_46 = { class: "icon" }
const _hoisted_47 = { class: "label" }
const _hoisted_48 = { class: "separator" }
const _hoisted_49 = { class: "separator__title" }
const _hoisted_50 = { class: "icon" }
const _hoisted_51 = { class: "label" }
const _hoisted_52 = { class: "icon" }
const _hoisted_53 = { class: "label" }
const _hoisted_54 = { class: "separator" }
const _hoisted_55 = { class: "separator__title" }
const _hoisted_56 = { class: "icon" }
const _hoisted_57 = { class: "label" }
const _hoisted_58 = { class: "label" }
const _hoisted_59 = { class: "label" }
const _hoisted_60 = { class: "label" }
const _hoisted_61 = { class: "icon" }
const _hoisted_62 = { class: "label" }
const _hoisted_63 = { class: "label" }
const _hoisted_64 = { class: "label" }
const _hoisted_65 = { class: "icon" }
const _hoisted_66 = { class: "label" }
const _hoisted_67 = { class: "label" }
const _hoisted_68 = { class: "label" }
const _hoisted_69 = { class: "label" }
const _hoisted_70 = { class: "label" }
const _hoisted_71 = { class: "icon" }
const _hoisted_72 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_House = _resolveComponent("House")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_Monitor = _resolveComponent("Monitor")!
  const _component_Film = _resolveComponent("Film")!
  const _component_Collection = _resolveComponent("Collection")!
  const _component_List = _resolveComponent("List")!
  const _component_Files = _resolveComponent("Files")!
  const _component_Setting = _resolveComponent("Setting")!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!
  const _component_User = _resolveComponent("User")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_Warning = _resolveComponent("Warning")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isUserRoleAdministrator)
        ? (_openBlock(), _createBlock(_component_el_menu, {
            key: 0,
            onSelect: _ctx.setRoute
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_menu_item, { index: "AdministrationAdministratorDashboardIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_3, [
                    _createVNode(_component_House)
                  ]),
                  _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.translation.dashboard), 1)
                ]),
                _: 1
              }),
              _createElementVNode("span", _hoisted_5, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.translation.content), 1)
              ]),
              _createVNode(_component_el_menu_item, { index: "AdministrationAdministratorChannelsIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_7, [
                    _createVNode(_component_Monitor)
                  ]),
                  _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.translation.channels), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_menu_item, { index: "AdministrationAdministratorVideosIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_9, [
                    _createVNode(_component_Film)
                  ]),
                  _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.translation.videos), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_menu_item, { index: "AdministrationAdministratorCategoriesIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_11, [
                    _createVNode(_component_Collection)
                  ]),
                  _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.translation.categories), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_menu_item, { index: "AdministrationAdministratorTagsIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_13, [
                    _createVNode(_component_List)
                  ]),
                  _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.translation.tags), 1)
                ]),
                _: 1
              }),
              _createElementVNode("span", _hoisted_15, [
                _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.translation.pages), 1)
              ]),
              _createVNode(_component_el_menu_item, { index: "AdministrationAdministratorPagesViewIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_17, [
                    _createVNode(_component_House)
                  ]),
                  _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.translation.homePage), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_menu_item, { index: "AdministrationAdministratorPagesIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_19, [
                    _createVNode(_component_Files)
                  ]),
                  _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.translation.pages), 1)
                ]),
                _: 1
              }),
              _createElementVNode("span", _hoisted_21, [
                _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.translation.settings), 1)
              ]),
              _createVNode(_component_el_sub_menu, { index: "AdministrationAdministratorSettingsIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_23, [
                    _createVNode(_component_Setting)
                  ]),
                  _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.translation.settings), 1)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_el_menu_item, { index: "AdministrationAdministratorSettingsAlgorithmsIndex" }, {
                    title: _withCtx(() => [
                      _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.translation.algorithms), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_menu_item, { index: "AdministrationAdministratorSettingsFunctionalitiesIndex" }, {
                    title: _withCtx(() => [
                      _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.translation.functionalities), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_menu_item, { index: "AdministrationAdministratorSettingsGroupsIndex" }, {
                    title: _withCtx(() => [
                      _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.translation.groups), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_el_menu_item, { index: "AdministrationAdministratorUsersIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_28, [
                    _createVNode(_component_User)
                  ]),
                  _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.translation.users), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onSelect"]))
        : _createCommentVNode("", true),
      (_ctx.isUserRoleClient)
        ? (_openBlock(), _createBlock(_component_el_menu, {
            key: 1,
            onSelect: _ctx.setRoute
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_menu_item, { index: "AdministrationClientDashboardIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_30, [
                    _createVNode(_component_House)
                  ]),
                  _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.translation.dashboard), 1)
                ]),
                _: 1
              }),
              _createElementVNode("span", _hoisted_32, [
                _createElementVNode("span", _hoisted_33, _toDisplayString(_ctx.translation.content), 1)
              ]),
              _createVNode(_component_el_menu_item, { index: "AdministrationClientChannelsIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_34, [
                    _createVNode(_component_Monitor)
                  ]),
                  _createElementVNode("span", _hoisted_35, _toDisplayString(_ctx.translation.channels), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onSelect"]))
        : _createCommentVNode("", true),
      (_ctx.isUserRoleSuperAdministrator)
        ? (_openBlock(), _createBlock(_component_el_menu, {
            key: 2,
            onSelect: _ctx.setRoute
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_menu_item, { index: "AdministrationSuperAdministratorDashboardIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_36, [
                    _createVNode(_component_House)
                  ]),
                  _createElementVNode("span", _hoisted_37, _toDisplayString(_ctx.translation.dashboard), 1)
                ]),
                _: 1
              }),
              _createElementVNode("span", _hoisted_38, [
                _createElementVNode("span", _hoisted_39, _toDisplayString(_ctx.translation.content), 1)
              ]),
              _createVNode(_component_el_menu_item, { index: "AdministrationSuperAdministratorChannelsIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_40, [
                    _createVNode(_component_Monitor)
                  ]),
                  _createElementVNode("span", _hoisted_41, _toDisplayString(_ctx.translation.channels), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_menu_item, { index: "AdministrationSuperAdministratorVideosIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_42, [
                    _createVNode(_component_Film)
                  ]),
                  _createElementVNode("span", _hoisted_43, _toDisplayString(_ctx.translation.videos), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_menu_item, { index: "AdministrationSuperAdministratorCategoriesIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_44, [
                    _createVNode(_component_Collection)
                  ]),
                  _createElementVNode("span", _hoisted_45, _toDisplayString(_ctx.translation.categories), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_menu_item, { index: "AdministrationSuperAdministratorTagsIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_46, [
                    _createVNode(_component_List)
                  ]),
                  _createElementVNode("span", _hoisted_47, _toDisplayString(_ctx.translation.tags), 1)
                ]),
                _: 1
              }),
              _createElementVNode("span", _hoisted_48, [
                _createElementVNode("span", _hoisted_49, _toDisplayString(_ctx.translation.pages), 1)
              ]),
              _createVNode(_component_el_menu_item, { index: "AdministrationSuperAdministratorPagesViewIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_50, [
                    _createVNode(_component_House)
                  ]),
                  _createElementVNode("span", _hoisted_51, _toDisplayString(_ctx.translation.homePage), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_menu_item, { index: "AdministrationSuperAdministratorPagesIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_52, [
                    _createVNode(_component_Files)
                  ]),
                  _createElementVNode("span", _hoisted_53, _toDisplayString(_ctx.translation.pages), 1)
                ]),
                _: 1
              }),
              _createElementVNode("span", _hoisted_54, [
                _createElementVNode("span", _hoisted_55, _toDisplayString(_ctx.translation.settings), 1)
              ]),
              _createVNode(_component_el_sub_menu, { index: "AdministrationSuperAdministratorSettingsIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_56, [
                    _createVNode(_component_Setting)
                  ]),
                  _createElementVNode("span", _hoisted_57, _toDisplayString(_ctx.translation.settings), 1)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_el_menu_item, { index: "AdministrationSuperAdministratorSettingsAlgorithmsIndex" }, {
                    title: _withCtx(() => [
                      _createElementVNode("span", _hoisted_58, _toDisplayString(_ctx.translation.algorithms), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_menu_item, { index: "AdministrationSuperAdministratorSettingsFunctionalitiesIndex" }, {
                    title: _withCtx(() => [
                      _createElementVNode("span", _hoisted_59, _toDisplayString(_ctx.translation.functionalities), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_menu_item, { index: "AdministrationSuperAdministratorSettingsGroupsIndex" }, {
                    title: _withCtx(() => [
                      _createElementVNode("span", _hoisted_60, _toDisplayString(_ctx.translation.groups), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_el_sub_menu, { index: "AdministrationSuperAdministratorLogsIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_61, [
                    _createVNode(_component_Warning)
                  ]),
                  _createElementVNode("span", _hoisted_62, _toDisplayString(_ctx.translation.logs), 1)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_el_menu_item, { index: "AdministrationSuperAdministratorLogsExceptionLogsIndex" }, {
                    title: _withCtx(() => [
                      _createElementVNode("span", _hoisted_63, _toDisplayString(_ctx.translation.exceptionLogs), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_menu_item, { index: "AdministrationSuperAdministratorLogsActivityLogsIndex" }, {
                    title: _withCtx(() => [
                      _createElementVNode("span", _hoisted_64, _toDisplayString(_ctx.translation.activityLogs), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_el_sub_menu, { index: "AdministrationSuperAdministratorNotificationsIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_65, [
                    _createVNode(_component_Warning)
                  ]),
                  _createElementVNode("span", _hoisted_66, _toDisplayString(_ctx.translation.notifications), 1)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_el_menu_item, { index: "AdministrationSuperAdministratorNotificationsDevicesIndex" }, {
                    title: _withCtx(() => [
                      _createElementVNode("span", _hoisted_67, _toDisplayString(_ctx.translation.devices), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_menu_item, { index: "AdministrationSuperAdministratorNotificationsSubscriptionsIndex" }, {
                    title: _withCtx(() => [
                      _createElementVNode("span", _hoisted_68, _toDisplayString(_ctx.translation.subscriptions), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_menu_item, { index: "AdministrationSuperAdministratorNotificationsEventsIndex" }, {
                    title: _withCtx(() => [
                      _createElementVNode("span", _hoisted_69, _toDisplayString(_ctx.translation.events), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_menu_item, { index: "AdministrationSuperAdministratorNotificationsScheduledEventsIndex" }, {
                    title: _withCtx(() => [
                      _createElementVNode("span", _hoisted_70, _toDisplayString(_ctx.translation.scheduledEvents), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_el_menu_item, { index: "AdministrationSuperAdministratorUsersIndex" }, {
                title: _withCtx(() => [
                  _createElementVNode("span", _hoisted_71, [
                    _createVNode(_component_User)
                  ]),
                  _createElementVNode("span", _hoisted_72, _toDisplayString(_ctx.translation.users), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onSelect"]))
        : _createCommentVNode("", true)
    ])
  ]))
}