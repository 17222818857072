import { createApp } from "vue";
import app from "./app.vue";
import router from "./router";
import store from "./store";
import vueDebounce from "vue-debounce";

createApp(app)
  .use(store)
  .use(router)
  .directive("debounce", vueDebounce({ lock: true }))
  .mount("#app");
