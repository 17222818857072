const translations = [
  {
    accessToDataIsForbidden: {
      hr: "Pristup podacima nije moguć",
      en: "Access to data is forbidden",
    },
    activityLogs: {
      hr: "Zapisi aktivnosti",
      en: "Activity logs",
    },
    algorithm: {
      hr: "Algoritam",
      en: "Algorithm",
    },
    algorithms: {
      hr: "Algoritmi",
      en: "Algorithms",
    },
    add: {
      hr: "Dodajte",
      en: "Add",
    },
    ascending: {
      hr: "Uzlazno",
      en: "Ascending",
    },
    app: {
      hr: "Aplikacija",
      en: "Application",
    },
    administrator: {
      hr: "Administrator",
      en: "Administrator",
    },
    areYouSure: {
      hr: "Jeste li sigurni?",
      en: "Are you sure?",
    },
    back: {
      hr: "Povratak",
      en: "Back",
    },
    category: {
      hr: "Kategorija",
      en: "Category",
    },
    categories: {
      hr: "Kategorije",
      en: "Categories",
    },
    change: {
      hr: "Promjenite",
      en: "Change",
    },
    choose: {
      hr: "Odaberite",
      en: "Choose",
    },
    channel: {
      hr: "Kanal",
      en: "Channel",
    },
    channels: {
      hr: "Kanali",
      en: "Channels",
    },
    channelOwners: {
      hr: "Vlasnici kanala",
      en: "Channel Owners",
    },
    channelGroups: {
      hr: "Kanali",
      en: "Channels",
    },
    changePassword: {
      hr: "Promjena lozinke",
      en: "Change password",
    },
    checkYourCredentials: {
      hr: "Provjerite svoje podatke",
      en: "Check your credentials",
    },
    client: {
      hr: "Klijent",
      en: "Client",
    },
    copiedToClipboard: {
      hr: "Kopirano u međuspremnik",
      en: "Copied to clipboard",
    },
    content: {
      hr: "Sadržaj",
      en: "Content",
    },
    continue: {
      hr: "Nastavite",
      en: "Continue",
    },
    createdAt: {
      hr: "Kreirano",
      en: "Created at",
    },
    dashboard: {
      hr: "Radna ploča",
      en: "Dashboard",
    },
    dataNotFound: {
      hr: "Podatak nije pronađen",
      en: "Data not found",
    },
    date: {
      hr: "Datum",
      en: "Date",
    },
    dateTime: {
      hr: "Datum i vrijeme",
      en: "Datetime",
    },
    description: {
      hr: "Opis",
      en: "Description",
    },
    delete: {
      hr: "Uklanjanje",
      en: "Delete",
    },
    descending: {
      hr: "Silazno",
      en: "Descending",
    },
    devices: {
      hr: "Uređaji",
      en: "Devices",
    },
    document: {
      hr: "Dokument",
      en: "Document",
    },
    download: {
      hr: "Preuzimanje",
      en: "Download",
    },
    edit: {
      hr: "Uredite",
      en: "Edit",
    },
    emailCannotBeSent: {
      hr: "E-mail se ne može poslati",
      en: "E-amil cannot be sent",
    },
    exceptionLogs: {
      hr: "Zapisi grešaka",
      en: "Exception Logs",
    },
    email: {
      hr: "E-mail",
      en: "E-mail",
    },
    event: {
      hr: "Događaj",
      en: "Event",
    },
    events: {
      hr: "Događaji",
      en: "Events",
    },
    filter: {
      hr: "Filter",
      en: "Filter",
    },
    forgottenPassword: {
      hr: "Zaboravljena lozinka?",
      en: "Forgotten password?",
    },
    functionality: {
      hr: "Funkcionalnost",
      en: "Functionality",
    },
    functionalities: {
      hr: "Funkcionalnosti",
      en: "Functionalities",
    },
    functionalityGroups: {
      hr: "Funkcionalnosti",
      en: "Functionality",
    },
    getRecoveryToken: {
      hr: "Ishodi token za oporavak",
      en: "Get recovery token",
    },
    groups: {
      hr: "Grupe",
      en: "Groups",
    },
    hello: {
      hr: "Bok",
      en: "Hello",
    },
    homePage: {
      hr: "Početna stranica",
      en: "Home page",
    },
    horizontal: {
      hr: "Horizontalno",
      en: "Horizontal",
    },
    information: {
      hr: "Informacije",
      en: "Information",
    },
    label: {
      hr: "Oznaka",
      en: "Label",
    },
    login: {
      hr: "Prijava",
      en: "Login",
    },
    logs: {
      hr: "Zapisi",
      en: "Logs",
    },
    longtext: {
      hr: "Dugi tekst",
      en: "Long text",
    },
    logout: {
      hr: "Odjava",
      en: "Logout",
    },
    menu: {
      hr: "Izbornik",
      en: "Menu",
    },
    method: {
      hr: "Metoda",
      en: "Method",
    },
    message: {
      hr: "Poruka",
      en: "Message",
    },
    model: {
      hr: "Model",
      en: "Model",
    },
    modelId: {
      hr: "Model - ID",
      en: "Model - ID",
    },
    modelNotFound: {
      hr: "Model nije pronađen",
      en: "Model not found",
    },
    name: {
      hr: "Ime",
      en: "Name",
    },
    newVideo: {
      hr: "Novi video",
      en: "New video",
    },
    no: {
      hr: "Ne",
      en: "No",
    },
    noDataYet: {
      hr: "Trenutno nema podataka...",
      en: "No data yet...",
    },
    notifications: {
      hr: "Notifikacije",
      en: "Notifications",
    },
    numberOfChannels: {
      hr: "Broj kanala",
      en: "Number of Channels",
    },
    numberOfVideos: {
      hr: "Broj videa",
      en: "Number of Videos",
    },
    numberOfViews: {
      hr: "Broj pregleda",
      en: "Number of Views",
    },
    numberOfUsers: {
      hr: "Broj korisnika",
      en: "Number of Users",
    },
    releaseDate: {
      hr: "Datum objave",
      en: "Release Date",
    },
    responseCode: {
      hr: "Kod odgovora",
      en: "Response Code",
    },
    responseMessage: {
      hr: "Poruka odgovora",
      en: "Response Message",
    },
    role: {
      hr: "Uloga",
      en: "Role",
    },
    route: {
      hr: "Ruta",
      en: "Route",
    },
    orientation: {
      hr: "Orijentacija",
      en: "Orientation",
    },
    order: {
      hr: "Redosljed",
      en: "Order",
    },
    orderBy: {
      hr: "Sortiraj prema",
      en: "Order by",
    },
    orderType: {
      hr: "Sortiraj redom",
      en: "Order type",
    },
    paginate: {
      hr: "Paginacija",
      en: "Paginate",
    },
    page: {
      hr: "Stranica",
      en: "Page",
    },
    pages: {
      hr: "Stranice",
      en: "Pages",
    },
    photo: {
      hr: "Fotografija",
      en: "Photo",
    },
    photos: {
      hr: "Fotografije",
      en: "Photos",
    },
    password: {
      hr: "Lozinka",
      en: "Password",
    },
    passwordHasBeenChanged: {
      hr: "Lozinka je promjenjena",
      en: "Password has been changed",
    },
    passwordIsMissing: {
      hr: "Nedostaje lozinka",
      en: "Password is missing",
    },
    passwordRecovery: {
      hr: "Oporavak lozinke",
      en: "Password recovery",
    },
    passwordRecoveryEmailHasBeenSent: {
      hr: "E-mail za oporavak lozinke je poslan",
      en: "Password recovery email has been sent",
    },
    pleaseLoginAgain: {
      hr: "Molimo prijavite se ponovno",
      en: "Please login again",
    },
    pleaseVerifyYourAccount: {
      hr: "Molimo verificirajte svoj račun",
      en: "Please verify your account",
    },
    pleaseUploadPhoto: {
      hr: "Molimo prenesite fotografiju",
      en: "Please upload photo",
    },
    private: {
      hr: "Privatno",
      en: "Private",
    },
    premierDate: {
      hr: "Datum premijere",
      en: "Premier Date",
    },
    public: {
      hr: "Javno",
      en: "Public",
    },
    publicVideo: {
      hr: "Javni video",
      en: "Public video",
    },
    scheduledEvents: {
      hr: "Zakazani događaji",
      en: "Scheduled events",
    },
    sent: {
      hr: "Poslano",
      en: "Sent",
    },
    scheduledAt: {
      hr: "Zakazano",
      en: "Scheduled at",
    },
    search: {
      hr: "Pretraga",
      en: "Search",
    },
    seeYouSoon: {
      hr: "Vidimo se uskoro",
      en: "See you soon",
    },
    settings: {
      hr: "Postavke",
      en: "Settings",
    },
    somethingWentWrong: {
      hr: "Nešto je pošlo po krivu",
      en: "Something went wrong",
    },
    statistics: {
      hr: "Statistika",
      en: "Statistics",
    },
    status: {
      hr: "Status",
      en: "Status",
    },
    subscriptions: {
      hr: "Pretplate",
      en: "Subscriptions",
    },
    successfullyDeleted: {
      hr: "Uspješno obrisano",
      en: "Successfully deleted",
    },
    successfullySaved: {
      hr: "Uspješno spremljeno",
      en: "Successfully saved",
    },
    superAdministrator: {
      hr: "Super Administrator",
      en: "Super Administrator",
    },
    tag: {
      hr: "Oznaka",
      en: "Tag",
    },
    tags: {
      hr: "Oznake",
      en: "Tags",
    },
    text: {
      hr: "Tekst",
      en: "Text",
    },
    title: {
      hr: "Naziv",
      en: "Title",
    },
    token: {
      hr: "Token",
      en: "Token",
    },
    tokenIsValid: {
      hr: "Token je ispravan",
      en: "Token is valid",
    },
    tokenHasExpired: {
      hr: "Token je istekao",
      en: "Token has expired",
    },
    type: {
      hr: "Tip",
      en: "Type",
    },
    typeYourNewPassword: {
      hr: "Unesite novu lozinku",
      en: "Type your new password",
    },
    unknown: {
      hr: "Nepoznato",
      en: "Unknown",
    },
    upload: {
      hr: "Prijenos",
      en: "Upload",
    },
    unionByUserGroup: {
      hr: "Unija s grupom korisnika",
      en: "Union by user group",
    },
    users: {
      hr: "Korisnici",
      en: "Users",
    },
    user: {
      hr: "Korisnik",
      en: "User",
    },
    userAlreadyExists: {
      hr: "Korisnik već postoji",
      en: "User already exists",
    },
    userAgent: {
      hr: "Korisnički agent",
      en: "User agent",
    },
    userNotFound: {
      hr: "Korisnik nije pronađen",
      en: "User not found",
    },
    userGroups: {
      hr: "Korisnici",
      en: "Users",
    },
    url: {
      hr: "URL",
      en: "URL",
    },
    verification: {
      hr: "Verifikacija",
      en: "Verification",
    },
    vertical: {
      hr: "Vertikalno",
      en: "Vertical",
    },
    video: {
      hr: "Video",
      en: "Video",
    },
    videos: {
      hr: "Video zapisi",
      en: "Videos",
    },
    viewer: {
      hr: "Pregled",
      en: "Viewer",
    },
    yes: {
      hr: "Da",
      en: "Yes",
    },
    yesIAm: {
      hr: "Da, jesam",
      en: "Yes, I am",
    },
    youAreLoggedOut: {
      hr: "Odjavljeni ste",
      en: "You are logged out",
    },
    yourAccountHasBeenVerified: {
      hr: "Vaš račun je verificiran",
      en: "Your account has been verified",
    },
    waiting: {
      hr: "Čekanje",
      en: "Waiting",
    },
    welcomeBack: {
      hr: "Dobro došli nazad",
      en: "Welcome back",
    },
    welcomeToOurPlatform: {
      hr: "Dobro došli na našu platformu",
      en: "Welcome to our platform",
    },
    widgetBlocks: {
      hr: "Widget blokovi",
      en: "Widget Blocks",
    },
    widgets: {
      hr: "Widgeti",
      en: "Widgets",
    },
    with: {
      hr: "Zajedno sa",
      en: "with",
    },
    where: {
      hr: "Gdje",
      en: "Where",
    },
  },
];
export default translations;
